var GMBImageResize = {
  bookCoverSizeQuery: {
    'xs': '?width=100',
    'sm': '?width=140',
    'md': '?width=280',
    'lg': '?width=420',
    'xl': '?width=1000'
  },

  notebookCoverSizeQuery: {
    'sm': '?width=140',
    'md': '?width=380',
    'lg': '?width=760',
  },

  gridBreakpoints: {
    sm: 680,
    md: 992,
    lg: 1024,
    xl: 1200
  },

  rewriteUrl: function (url) {
    if (!url || typeof url !== 'string' || url.startsWith('blob:')) {
      return url;
    }
    return url.replace(/unchained-test-2\.bitmark\.cloud\/cdn\/storage/gm, 'resources-test.getmorebrain.com/cdn/storage')
      .replace(/s3\.eu-west-1\.amazonaws\.com\/unchained-resources-test/gm, 'resources-test.getmorebrain.com')
      .replace(/s3\.eu-west-1\.amazonaws\.com\/unchained-resources-prod/gm, 'resources.getmorebrain.com')
      .replace(/s3\.eu-west-1\.amazonaws\.com\/unchained-resources-swisslife/gm, 'resources-swisslife.getmorebrain.com')
      .replace(/s3\.eu-west-1\.amazonaws\.com\/unchained-resources-iffp/gm, 'resources-iffp.bitmark.cloud')
      .replace(/bitmark-full-content-test\.s3-eu-west-1\.amazonaws.com/gm, 'book-content-test.getmorebrain.com')
      .replace(/bitmark-full-content-prod\.s3-eu-west-1\.amazonaws.com/gm, 'book-content.getmorebrain.com')
      .replace(/resource\.bitmark\.cloud/gm, 'resources.getmorebrain.com')
      .replace(/resources\.bitmark\.cloud/gm, 'resources.getmorebrain.com')
      .replace(/docs\.bitmark\.cloud/gm, 'docs.getmorebrain.com')
      .replace(/resource-swisslife\.bitmark\.cloud/gm, 'resource-swisslife.getmorebrain.com')
      .replace(/docs\.bitbook\.education/gm, 'docs.getmorebrain.com')
      .replace(/resources-test\.bitmark\.cloud/gm, 'resources-test.getmorebrain.com')
      .replace(/bitbook-resources-test\.s3-eu-west-1\.amazonaws\.com/g, 'resources-test.getmorebrain.com')
      .replace('http://', 'https://');
  },

  rewriteUrlAndResize: function (url, imageContext, imageSize) {
    if(!url){
      return '';
    }
    var baseUrl = this.rewriteUrl(url);

    var isLocalBlob = url.startsWith('blob:')

    if (baseUrl?.indexOf('getmorebrain.com') === -1) {
      return baseUrl;
    }

    if (imageContext === 'book-cover' && !isLocalBlob) {
      if (this.bookCoverSizeQuery[imageSize]) {
        baseUrl += this.bookCoverSizeQuery[imageSize];
      }
    }
    if (imageContext === 'notebook-cover' && !isLocalBlob) {
      if (this.notebookCoverSizeQuery[imageSize]) {
        baseUrl += this.notebookCoverSizeQuery[imageSize];
      }
    }
    if (imageContext === 'reader-resource') {
      if (['image-portrait', 'image-super-wide', 'image-landscape'].indexOf(imageSize) !== -1) {
        return baseUrl;
      }
      const readerWidth = document.querySelector('.shop-header') ? document.querySelector('.shop-header').clientWidth : window.innerWidth * 0.4632;
      const mobileReaderWidth = window.innerWidth;
      if (readerWidth && !isLocalBlob) {
        let width;

        if (this.isMobile()) {
          const closestDoubleWidth = Math.ceil(mobileReaderWidth * 2 / 200) * 200
          width = closestDoubleWidth;
        } else {
          //the 0.4632 is here because when making the request for the image, the reader is not yet rendered so we do not know its size
          //this is the best approximation for a regular screen, not needed for mobile where it's full-width
          const closestDoubleWidth = Math.ceil(readerWidth * 2 / 400) * 400
          width = closestDoubleWidth;
        }
        baseUrl += `?width=${width}`;
      } else {
        if (this.isMobile()) {
          baseUrl += '?width=800';
        } else {
          baseUrl += '?width=1600';
        }
      }
    }

    return baseUrl;
  },

  isMobile: function () {
    return window.innerWidth < this.gridBreakpoints.sm;
  }
};
